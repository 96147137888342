import moment from 'moment';
import classnames from 'classnames';
import { Button, Tooltip, Spin } from 'antd';

import { INPUT_TYPES, isSaudi } from 'constants/helper';
import { handleOnTicketUrlClick } from 'utils/wallet';
import {
  COMPENSATION_TABLE_APPROVAL_TYPES,
  DEPARTMENTS,
  DISPLAYED_COMPENSATION_TYPE
} from './compensation';

import { fmt } from 'components/IntlWrapper/IntlWrapper';
import ConfirmationToolTip from 'components/Wallet/components/WalletCompensation/CompensationNewTab/components/ConfirmationTooltip/ConfirmationToolTip';
import PriceBreakdownToolTip from 'components/AddCompensationFormModal/components/PriceBreakdownToolTip/PriceBreakdownToolTip';
import DepartmentsAndApprovalToolTip from 'components/Wallet/components/WalletCompensation/CompensationNewTab/components/DepartmentsAndApprovalToolTip/DepartmentsAndApprovalToolTip';

import { ReactComponent as FirstRowIcon } from 'assets/bosta-icons/compensation-table-first-record.svg';
import { ReactComponent as CheckIcon } from 'assets/bosta-icons/Check.svg';
import { ReactComponent as CloseIcon } from 'assets/bosta-icons/Close.svg';
import { ReactComponent as UnpaidIcon } from 'assets/imgRevamp/unpaid-unfilled-dot.svg';
import { ReactComponent as PaidIcon } from 'assets/imgRevamp/paid-filled-dot.svg';
import { ReactComponent as ToolTipIcon } from 'assets/bosta-icons/Tooltip.svg';
import { ReactComponent as EyeIcon } from 'assets/bosta-icons/Eye.svg';

export const PAYMENT_TYPE = {
  POST_PAID: 'Postpaid',
  PRE_PAID: 'Prepaid'
};

export const CASHOUT_FREQUENCY = {
  WEEKLY: 'Weekly',
  DAILY: 'Daily',
  NEXT_DAY: 'Next Day'
};

export const PAYMENT_SCHEDULE = {
  DAILY: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday'],
  WEEKLY_SUN: ['Sunday'],
  WEEKLY_MON: ['Monday'],
  WEEKLY_WED: ['Wednesday'],
  TWO_DAYS: ['Monday', 'Wednesday'],
  THREE_DAYS: ['Sunday', 'Tuesday', 'Thursday']
};

export const BALANCE_CATEGORIES = {
  CASH_COLLECTION_CYCLE: 0,
  BOSTA_FEES_CYCLE: 1,
  CASH_OUT: 2,
  COMPENSATION: 3,
  PACKING_MATERIAL: 4,
  TOP_UP: 5,
  REJECTED_CASHOUT: 8,
  BALANCE_ADJUSTMENT_POSITIVE: 9,
  BALANCE_ADJUSTMENT_NEGATIVE: 10,
  BORROW: 11,
  PICKUP_FEES: 13
};

export const FLYER_SIZE = {
  Normal: fmt({ id: 'wallet.cycles.flyer_size.normal' }),
  Large: fmt({ id: 'wallet.cycles.flyer_size.large' }),
  'X-Large': fmt({ id: 'wallet.cycles.flyer_size.x_large' }),
  'White Bag': fmt({ id: 'wallet.cycles.flyer_size.xx_large' }),
  Bulky: fmt({ id: 'wallet.cycles.flyer_size.bulky' }),
  'Light Bulky': fmt({ id: 'wallet.cycles.flyer_size.light_bulky' }),
  'Heavy Bulky': fmt({ id: 'wallet.cycles.flyer_size.heavy_bulky' })
};

export const PAYMENT_TRANSFER_METHOD = {
  FAWRY: 'Fawry',
  BANK_TRANSFER: 'Bank transfer'
};

export const FILTERED_BALANCE_CATEGORIES = {
  'cash-collection': 0,
  'bosta-fees': 1
};

export const SORT_ORDER = {
  ascend: 'asc',
  descend: 'desc'
};

export const WALLET_VIDEO_URL = 'https://www.youtube.com/embed/A6lNH9zNDOk';
export const WALLET_VIDEO_URL_ARABIC =
  'https://www.youtube.com/embed/EIekRBhjW_A';

export const TABLE_PAGE_LIMIT = 50;
export const TABLE_PAGE_SIZE_OPTIONS = [50, 100, 200];

export const PERCENTAGE = 'PERCENTAGE';

export const ALL_PAID = 'All paid';

export const BALANCE_ADJUSTMENT_CATEGORIES = {
  FULFILLMENT_SETTLEMENT: 'Fulfillment Settlement',
  COMPENSATION_VARIANCE: 'Compensation Variance',
  OVERDUE_SETTLEMENT: 'Overdue Settlement',
  COD_SETTLEMENT: 'COD Settlement',
  BORROW_ADJUSTMENT: 'Borrow Adjustment',
  EXTRA_FEES: 'Extra Fees',
  INTERNATIONAL_TRANSFER_FEES: 'International Transfer Fees',
  FULFILLMENT_PALLET_FEES: 'Fulfillment Pallet Fees',
  DONATION: 'Donation',
  FULFILLMENT_STORAGE_FEES: 'Fulfillment Storage Fees',
  PICKUP_FEES_ADJUSTMENT: 'Pick-up Fees Adjustment',
  TRANSFER_FEES: 'Transfer Fees'
};

export const BALANCE_ADJUSTMENT_MAPPING = {
  [BALANCE_ADJUSTMENT_CATEGORIES.FULFILLMENT_SETTLEMENT]:
    'fulfillment_settlement',
  [BALANCE_ADJUSTMENT_CATEGORIES.COMPENSATION_VARIANCE]:
    'compensation_variance',
  [BALANCE_ADJUSTMENT_CATEGORIES.OVERDUE_SETTLEMENT]: 'overdue_settlement',
  [BALANCE_ADJUSTMENT_CATEGORIES.COD_SETTLEMENT]: 'cod_settlement',
  [BALANCE_ADJUSTMENT_CATEGORIES.BORROW_ADJUSTMENT]: 'borrow_adjustment',
  [BALANCE_ADJUSTMENT_CATEGORIES.EXTRA_FEES]: 'extra_fees',
  [BALANCE_ADJUSTMENT_CATEGORIES.INTERNATIONAL_TRANSFER_FEES]:
    'international_transfer_fees',
  [BALANCE_ADJUSTMENT_CATEGORIES.FULFILLMENT_PALLET_FEES]:
    'fulfillment_pallet_fees',
  [BALANCE_ADJUSTMENT_CATEGORIES.DONATION]: 'donation',
  [BALANCE_ADJUSTMENT_CATEGORIES.FULFILLMENT_STORAGE_FEES]:
    'fulfillment_storage_fees',
  [BALANCE_ADJUSTMENT_CATEGORIES.PICKUP_FEES_ADJUSTMENT]:
    'pickup_Fees_adjustment',
  [BALANCE_ADJUSTMENT_CATEGORIES.TRANSFER_FEES]: 'transfer_fees'
};
export const COMPENSATION_TEAM = {
  FLEET_TEAM: 'FLEET',
  EXPERIENCE_TEAM: 'EXPERIENCE',
  FINANCE_TEAM: 'FINANCE'
};

export const COMPENSATION_TEAM_TAB_MAPPING = {
  FLEET_TEAM: 0,
  EXPERIENCE_TEAM: 1,
  FINANCE_TEAM: 2
};

export const COMPENSATION_RECORD_STATUS = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  FIRST_APPROVED_STATUS: 'FIRST_APPROVED',
  PENDING_FIRST_APPROVAL_STATUS: 'PENDING_FIRST_APPROVAL'
};

export const COMPENSATION_WALLET_STATUS = {
  PENDING_APPROVAL: 'N/A',
  REJECTED: 'N/A',
  APPROVED_NOT_PAID: 'Unpaid',
  APPROVED_PAID: 'Paid'
};

export const COMPENSATION_TYPE = {
  FREE_COMPENSATION_TYPE: 'FREE',
  CREDIT_COMPENSATION_TYPE: 'CREDIT'
};

export const COMPENSATION_TABS = {
  COMPLETED_TAB: 'COMPLETED',
  NEW_TAB: 'NEW',
  ALL_REQUESTS_TAB: 'ALL'
};

export const COMPENSATION_WALLET_CATEGORY = {
  FREE_ORDERS: 'Free Orders',
  WRONG_PACKAGE_SIZE: 'Wrong Package Size',
  LOST_OR_DAMAGE: 'Lost/Damage'
};

export const COMPENSATION_DEPARTMENTS = {
  OPs: 'ops',
  Fleet: 'fleet',
  Experience: 'exp',
  CS: 'cs',
  'AM (Red)': 'amRed',
  'AM (Silver/Gold)': 'amSilver',
  'AM (New B.Lines)': 'amNew'
};

export const COMPENSATION_WALLET_MAPPED_VALUES = {
  PAID: 'Paid',
  UNPAID: 'Unpaid',
  NA: 'N/A'
};

export const CURRENT_NEW_TAB_VALUE_MAPPING = {
  0: 'FLEET',
  1: 'EXPERIENCE',
  2: 'FINANCE'
};

export const COMPENSATION_TABS_MAPPING = {
  NEW: '1',
  COMPLETED: '2',
  ALL: '3'
};

export const QUICK_FILTERS = [
  { label: 'Delivery Experience', value: 'FLEET' },
  { label: 'Investigation', value: 'EXPERIENCE' },
  { label: 'Finance Team', value: 'FINANCE' }
];

export const COLUMNS = ({
  handleOnApproveClick = () => {},
  handleOnRejectClick = () => {},
  approvalLoading = {},
  onUndoClick = () => {},
  fleetApprovalDisable,
  financeApprovalDisable,
  currentTabValue,
  handleMouseEnter,
  handleMouseLeave,
  deliveryDetails,
  deliveryLoading
}) => [
  {
    render: (text, record, index) =>
      record.children?.length && <FirstRowIcon />,
    width: 68,
    fixed: 'left'
  },
  {
    title: fmt({
      id: 'wallet.compensation.columns_title.order_id'
    }),
    dataIndex: 'order_id',
    fixed: 'left',
    width: 120,
    render: (orderId, record) => {
      return (
        orderId && (
          <div className="br-compensation-table__order-id-container">
            <span
              onClick={() =>
                handleOnTicketUrlClick(`/deliveries/${orderId}/details`, true)
              }
              className="br-compensation-table__order-id"
            >
              {orderId}
            </span>
            <Tooltip
              key={orderId}
              title={
                deliveryLoading[orderId] ? (
                  <Spin />
                ) : deliveryDetails[orderId] ? (
                  <PriceBreakdownToolTip
                    deliveryInfo={deliveryDetails[orderId]}
                  />
                ) : null
              }
              overlayClassName="br-compensation-order-info-tooltip-container"
              placement="right"
              arrowPointAtCenter
            >
              <EyeIcon
                onMouseEnter={() => {
                  handleMouseEnter(orderId);
                }}
                onMouseLeave={() => {
                  handleMouseLeave(orderId);
                }}
              />
            </Tooltip>
          </div>
        )
      );
    }
  },
  {
    title: fmt({
      id: 'wallet.compensation.columns_title.business'
    }),
    dataIndex: 'business',
    width: 185,
    render: (business) =>
      business && (
        <div className="br-compensation-table__business-info">
          <span className="br-compensation-table__business-info__business-name">
            {business.name}
          </span>
          <span className="br-compensation-table__business-id">
            {business.id}
          </span>
        </div>
      )
  },
  {
    title: fmt({
      id: 'wallet.compensation.columns_title.request_id'
    }),
    dataIndex: 'request_id',
    width: 115
  },
  {
    title: fmt({
      id: 'wallet.compensation.columns_title.sla'
    }),
    dataIndex: 'sla',
    width: 132,
    render: (sla) => {
      let timeDiff = moment().diff(moment(sla), 'days');
      let slaTitle = 'Days';
      if (!timeDiff) {
        slaTitle = 'Hours';
        timeDiff = moment().diff(moment(sla), 'hours');
      }

      return (
        <div className="br-compensation-table__sla">
          <span
            className={classnames({
              'br-compensation-table__business-info__date_diff_green':
                slaTitle !== 'Days' || timeDiff < 3,
              'br-compensation-table__business-info__date_diff_red':
                slaTitle === 'Days' && timeDiff >= 3
            })}
          >
            {timeDiff} {slaTitle}
          </span>
          <span className="br-compensation-table__date_text">
            {moment(sla).format('MMM D, h:mm A')}
          </span>
        </div>
      );
    }
  },
  {
    title: fmt({
      id: 'wallet.compensation.columns_title.ticket_url'
    }),
    dataIndex: 'ticket_url',
    width: 96,
    render: (ticketUrl) => (
      <span
        className="br-compensation-table__ticket-url"
        onClick={() => handleOnTicketUrlClick(ticketUrl)}
      >
        Open Ticket
      </span>
    )
  },
  {
    title: fmt({
      id: 'wallet.compensation.columns_title.reason'
    }),
    dataIndex: 'reason',
    width: 184
  },
  {
    title: fmt({
      id: 'wallet.compensation.columns_title.type'
    }),
    dataIndex: 'type',
    width: 114,
    render: (type) => (
      <div className="br-compensation-table__business-info">
        <span className="br-compensation-table__business-info__business-name">
          {type.name}
        </span>
        <span className="br-compensation-table__business-id">
          {type.noOfOrders}
        </span>
      </div>
    )
  },
  {
    title: fmt({
      id: 'wallet.compensation.columns_title.amount'
    }),
    dataIndex: 'amount',
    width: 150,
    render: (amount, { assignedDepartments, type, order_id, child_TN }) => {
      const isCredit = type?.name === DISPLAYED_COMPENSATION_TYPE.CREDIT;
      const id = order_id || child_TN;

      return (
        <>
          <span className="br-compensation-info__price-text">
            {fmt(
              { id: `common.${isSaudi() ? 'SAR' : 'EGP'}_modified` },
              {
                cod: amount
              }
            )}
          </span>
          <Tooltip
            title={
              isCredit ? (
                <PriceBreakdownToolTip
                  isCredit={isCredit}
                  creditPricingInfo={assignedDepartments}
                  liabilityFactor={type?.noOfOrders}
                  totalCreditAmount={amount}
                />
              ) : deliveryLoading[id] ? (
                <Spin />
              ) : (
                <PriceBreakdownToolTip
                  isCredit={isCredit}
                  deliveryInfo={deliveryDetails[id]}
                />
              )
            }
            overlayClassName="br-compensation-info-tooltip-container"
          >
            <EyeIcon
              onMouseEnter={() => {
                handleMouseEnter(id);
              }}
              onMouseLeave={() => {
                handleMouseLeave(id);
              }}
            />
          </Tooltip>
        </>
      );
    }
  },
  {
    title: fmt({
      id: 'wallet.compensation.columns_title.department'
    }),
    dataIndex: 'department',
    width: 169,
    render: (departments) => {
      return (
        <div className="br-compensation-table__departments">
          {departments.map(
            (department, index) =>
              index <= 2 && (
                <span
                  key={index}
                  className={classnames({
                    'br-compensation-table__grey-department-label': index !== 1,
                    'br-compensation-table__green-department-label':
                      index === 1,
                    'br-compensation-table__more-department-label': index === 2
                  })}
                >
                  {index <= 1 ? (
                    DEPARTMENTS[department?.departmentName]?.name
                  ) : (
                    <Tooltip
                      title={
                        <DepartmentsAndApprovalToolTip
                          type={COMPENSATION_TABLE_APPROVAL_TYPES.DEPARTMENT}
                          list={departments}
                        />
                      }
                      overlayClassName="br-compensation-table__department-tooltip"
                    >
                      <span className="br-compensation-chip-text">
                        +{departments.length - 2}
                      </span>
                    </Tooltip>
                  )}
                </span>
              )
          )}
        </div>
      );
    }
  },
  {
    title: fmt({
      id: 'wallet.compensation.columns_title.assigned_on'
    }),
    dataIndex: 'assignedDepartments',
    width: 169,
    render: (assignedDepartments) => {
      return (
        <div className="br-compensation-table__departments">
          {assignedDepartments.map(
            ({ assignedOn }, index) =>
              index <= 2 &&
              assignedOn && (
                <span
                  key={index}
                  className={classnames({
                    'br-compensation-table__grey-department-label': index !== 1,
                    'br-compensation-table__green-department-label':
                      index === 1,
                    'br-compensation-table__more-department-label': index === 2
                  })}
                >
                  {index <= 1 ? (
                    assignedOn
                  ) : (
                    <Tooltip
                      title={
                        <DepartmentsAndApprovalToolTip
                          type={COMPENSATION_TABLE_APPROVAL_TYPES.ASSIGNED_ON}
                          list={assignedDepartments}
                        />
                      }
                      overlayClassName="br-compensation-table__department-tooltip"
                    >
                      <span className="br-compensation-chip-text">
                        +{assignedDepartments.length - 2}
                      </span>
                    </Tooltip>
                  )}
                </span>
              )
          )}
        </div>
      );
    }
  },
  {
    title: fmt({
      id: 'wallet.compensation.columns_title.contractor_name'
    }),
    dataIndex: 'assignedDepartments',
    width: 169,
    render: (assignedDepartments) => {
      return assignedDepartments.map(
        ({ contractorName }) =>
          contractorName && (
            <div className="br-compensation-table__departments">
              <span className={'br-compensation-table__grey-department-label'}>
                {contractorName}
              </span>
            </div>
          )
      );
    }
  },
  {
    title: fmt({
      id: 'wallet.compensation.columns_title.liability'
    }),
    dataIndex: 'liability',
    width: 200,
    render: (liability) => {
      return liability?.length ? (
        <div className="br-compensation-table__departments">
          {liability.map((liability, id) => (
            <span
              key={id}
              className="br-compensation-table__grey-department-label"
            >
              {liability}
            </span>
          ))}
        </div>
      ) : (
        'No Liability'
      );
    }
  },
  {
    title: 'Investigation Reason',
    dataIndex: 'investigation',
    width: 200,
    render: (investigation) => {
      return (
        investigation?.find((item) => item?.reason)?.reason ||
        fmt({
          id: 'common.empty_field'
        })
      );
    }
  },
  {
    title: fmt({
      id: 'wallet.compensation.columns_title.approved_by'
    }),
    dataIndex: 'approved_by',
    width: 300,
    render: (approvedBy) => (
      <div className="br-compensation-table__approved-by">
        {approvedBy.map(
          (approval, index) =>
            index <= 2 && (
              <span
                key={index}
                className={classnames({
                  'br-compensation-table__grey-department-label': index !== 1,
                  'br-compensation-table__blue-department-label': index === 1,
                  'br-compensation-table__more-department-label': index === 2
                })}
              >
                {index <= 1 ? (
                  <Tooltip
                    title={
                      <DepartmentsAndApprovalToolTip
                        type="approvedBy"
                        list={approvedBy}
                      />
                    }
                    overlayClassName="br-compensation-table__approved-by-tooltip"
                  >
                    <span className="br-compensation-chip-text">
                      {approval.approvedBy}
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={
                      <DepartmentsAndApprovalToolTip
                        type="approvedBy"
                        list={approvedBy}
                      />
                    }
                    overlayClassName="br-compensation-table__approved-by-tooltip"
                  >
                    <span className="br-compensation-chip-text">
                      +{approvedBy.length - 2}
                    </span>
                  </Tooltip>
                )}
              </span>
            )
        )}
      </div>
    )
  },
  {
    title: fmt({
      id: 'wallet.compensation.columns_title.submitted_by'
    }),
    dataIndex: 'submitted_by',
    width: 164
  },
  {
    title: fmt({
      id: 'wallet.compensation.columns_title.fleet_exp_approval'
    }),
    dataIndex: 'fleet_exp_approval',
    width: 215,
    render: (approval, record) => (
      <div className="br-compensation-table__approve-action">
        {approval?.approved ? (
          <div className="br-compensation__approval-column-content">
            <span className="br-compensation-table__approve-action-approved">
              <CheckIcon />{' '}
              {fmt({
                id: 'wallet.compensation.approved'
              })}
            </span>
            <Tooltip
              title={
                <ConfirmationToolTip
                  type="approval"
                  takenBy={approval?.takenBy}
                />
              }
            >
              <ToolTipIcon className="br-compensation-table__approve-tooltip-icon" />
            </Tooltip>
            {approval?.showUndo && (
              <span
                className="br-compensation__undo-approval-text"
                onClick={() => onUndoClick(record)}
              >
                {fmt({
                  id: 'wallet.compensation.undo'
                })}
              </span>
            )}
          </div>
        ) : approval?.rejected ? (
          <div className="br-compensation__approval-column-content">
            <span className="br-compensation-table__approve-action-rejected">
              <CloseIcon />{' '}
              {fmt({
                id: 'wallet.compensation.rejected'
              })}
            </span>
            <Tooltip
              title={
                <ConfirmationToolTip
                  takenBy={approval?.takenBy}
                  rejectionReason={approval?.rejectionReason}
                />
              }
              overlayClassName="br-compensation-table__confirmation-tooltip"
            >
              <ToolTipIcon className="br-compensation-table__approve-tooltip-icon" />
            </Tooltip>
            {approval?.showUndo && (
              <span
                className="br-compensation__undo-approval-text"
                onClick={() => onUndoClick(record)}
              >
                {fmt({
                  id: 'wallet.compensation.undo'
                })}
              </span>
            )}
          </div>
        ) : approval?.isNA ? (
          'N/A'
        ) : (
          <>
            <Button
              loading={approvalLoading[record?.request_id]?.REJECTED}
              disabled={
                fleetApprovalDisable ||
                record?.currentAssignedTeam !== currentTabValue
              }
              onClick={() => handleOnRejectClick(record)}
            >
              {fmt({
                id: 'wallet.compensation.reject'
              })}
            </Button>
            <Button
              loading={approvalLoading[record?.request_id]?.APPROVED}
              type="primary"
              disabled={
                fleetApprovalDisable ||
                record?.currentAssignedTeam !== currentTabValue
              }
              onClick={() => handleOnApproveClick(record)}
            >
              {fmt({
                id: 'wallet.compensation.approve'
              })}
            </Button>
          </>
        )}
      </div>
    )
  },
  ...(![
    COMPENSATION_TEAM.EXPERIENCE_TEAM,
    COMPENSATION_TEAM.FLEET_TEAM
  ].includes(currentTabValue)
    ? [
        {
          title: fmt({
            id: 'wallet.compensation.columns_title.finance_approval'
          }),
          dataIndex: 'finance_approval',
          width: 172,
          render: (approval, record) => (
            <div className="br-compensation-table__approve-action">
              {approval?.approved ? (
                <div className="br-compensation__approval-column-content">
                  <span className="br-compensation-table__approve-action-approved">
                    <CheckIcon />{' '}
                    {fmt({
                      id: 'wallet.compensation.approved'
                    })}{' '}
                  </span>
                  <Tooltip
                    title={
                      <ConfirmationToolTip
                        type="approval"
                        takenBy={approval?.takenBy}
                      />
                    }
                  >
                    <ToolTipIcon className="br-compensation-table__approve-tooltip-icon" />
                  </Tooltip>
                  {approval?.showUndo && (
                    <span
                      className="br-compensation__undo-approval-text"
                      onClick={() => onUndoClick(record)}
                    >
                      {fmt({
                        id: 'wallet.compensation.undo'
                      })}
                    </span>
                  )}
                </div>
              ) : approval?.rejected ? (
                <div className="br-compensation__approval-column-content">
                  <span className="br-compensation-table__approve-action-rejected">
                    <CloseIcon />{' '}
                    {fmt({
                      id: 'wallet.compensation.rejected'
                    })}
                  </span>
                  <Tooltip
                    title={
                      <ConfirmationToolTip
                        takenBy={approval?.takenBy}
                        rejectionReason={approval?.rejectionReason}
                      />
                    }
                    overlayClassName="br-compensation-table__confirmation-tooltip"
                  >
                    <ToolTipIcon className="br-compensation-table__approve-tooltip-icon" />
                  </Tooltip>
                  {approval?.showUndo && (
                    <span
                      className="br-compensation__undo-approval-text"
                      onClick={() => onUndoClick(record)}
                    >
                      {fmt({
                        id: 'wallet.compensation.undo'
                      })}
                    </span>
                  )}
                </div>
              ) : approval?.isNA ? (
                'N/A'
              ) : (
                <>
                  <Button
                    loading={approvalLoading[record?.request_id]?.REJECTED}
                    disabled={
                      record?.status ===
                      COMPENSATION_RECORD_STATUS.PENDING_FIRST_APPROVAL_STATUS
                        ? true
                        : financeApprovalDisable ||
                          record?.currentAssignedTeam !== currentTabValue
                    }
                    onClick={() => handleOnRejectClick(record)}
                  >
                    {fmt({
                      id: 'wallet.compensation.reject'
                    })}
                  </Button>
                  <Button
                    loading={approvalLoading[record?.request_id]?.APPROVED}
                    type="primary"
                    disabled={
                      record?.status ===
                      COMPENSATION_RECORD_STATUS.PENDING_FIRST_APPROVAL_STATUS
                        ? true
                        : financeApprovalDisable ||
                          record?.currentAssignedTeam !== currentTabValue
                    }
                    onClick={() => handleOnApproveClick(record)}
                  >
                    {fmt({
                      id: 'wallet.compensation.approve'
                    })}
                  </Button>
                </>
              )}
            </div>
          )
        }
      ]
    : []),
  {
    title: fmt({
      id: 'wallet.compensation.columns_title.wallet_status'
    }),
    dataIndex: 'wallet_status',
    width: 156,
    render: (walletStatus, record) => (
      <div className="br-compensation-table__wallet-status">
        <span
          className={classnames('br-compensation-table__wallet-status-value', {
            'br-compensation-table__wallet-status-value-green':
              COMPENSATION_WALLET_STATUS[walletStatus.value] === 'Paid',
            'br-compensation-table__wallet-status-value-grey':
              COMPENSATION_WALLET_STATUS[walletStatus.value] === 'Unpaid'
          })}
        >
          {COMPENSATION_WALLET_STATUS[walletStatus.value] === 'N/A' ? (
            <Tooltip
              title={fmt({
                id:
                  walletStatus.value === 'PENDING_APPROVAL'
                    ? 'wallet.compensation.pending_approval_wallet_tooltip'
                    : 'wallet.compensation.rejected_approval_wallet_tooltip'
              })}
              overlayClassName="br-compensation-info-tooltip-container"
              className="br-compensation-info-tooltip__outer-text-na"
            >
              N/A
            </Tooltip>
          ) : (
            <Tooltip
              title={
                COMPENSATION_WALLET_STATUS[walletStatus.value] === 'Paid'
                  ? fmt(
                      {
                        id: 'wallet.compensation.paid_wallet_tooltip'
                      },
                      {
                        date: moment(record.updatedAt).format('ddd, D MMM')
                      }
                    )
                  : fmt(
                      {
                        id: 'wallet.compensation.unpaid_wallet_tooltip'
                      },
                      {
                        date: moment(record.updatedAt).format('ddd, D MMM'),
                        time: moment(record.updatedAt).format('h.mm A')
                      }
                    )
              }
              overlayClassName="br-compensation-info-tooltip-container"
              className="br-compensation-info-tooltip__outer-text"
            >
              {COMPENSATION_WALLET_STATUS[walletStatus.value] === 'Paid' ? (
                <PaidIcon />
              ) : (
                <UnpaidIcon />
              )}
              {COMPENSATION_WALLET_STATUS[walletStatus.value]}
            </Tooltip>
          )}
        </span>
        {walletStatus.value && <span>{walletStatus.subtitle}</span>}
      </div>
    )
  }
];

export const SIZES_SORT_WEIGHT = {
  Bulky: 6,
  Normal: 0,
  'White Bag': 3,
  'X-Large': 2,
  'Light Bulky': 4,
  Large: 1,
  'Heavy Bulky': 5
};

export const COMPENSATION_FILTERS = (tab) => [
  {
    label: fmt({
      id: 'wallet.compensation.filters.submitted_date'
    }),
    key: 'date',
    menuType: 'date'
  },
  {
    label: fmt({
      id: 'wallet.compensation.filters.department'
    }),
    key: 'departmentName',
    menuType: INPUT_TYPES.SELECT,
    menu: Object.values(DEPARTMENTS).map((item) => ({
      label: item.name,
      value: item.value
    }))
  },
  ...(tab === COMPENSATION_TABS.NEW_TAB
    ? []
    : [
        {
          label: fmt({
            id: 'wallet.compensation.filters.approval_date'
          }),
          key: 'financeApprovalDate',
          menuType: 'date'
        }
      ]),
  {
    label: fmt({
      id: 'wallet.compensation.filters.business_id'
    }),
    className: 'br-compensation__business-id-filter-text',
    key: 'businessId',
    placeholder: fmt({
      id: 'wallet.compensation.filters.business_id_placeholder'
    }),
    menuType: INPUT_TYPES.TEXT
  },
  {
    label: fmt({
      id: 'wallet.compensation.filters.request_id'
    }),
    className: 'br-compensation__business-id-filter-text',
    key: 'requestIds',
    placeholder: fmt({
      id: 'wallet.compensation.filters.multi_request_placeholder'
    }),
    menuType: INPUT_TYPES.MULTI_TEXT
  },
  {
    label: fmt({
      id: 'wallet.compensation.filters.order_id'
    }),
    className: 'br-compensation__business-id-filter-text',
    key: 'orderIds',
    placeholder: fmt({
      id: 'wallet.compensation.filters.multi_id_placeholder'
    }),
    menuType: INPUT_TYPES.MULTI_TEXT
  }
];

export const COMPENSATION_DEPARTMENTS_MAPPER = {
  OPs: 'OPs',
  Fleet: 'Delivery Experience',
  Experience: 'Investigation',
  CS: 'CS',
  'AM (Red)': 'AM (Red)',
  'AM (Silver/Gold)': 'AM (Silver/Gold)',
  'AM (New B.Lines)': 'Sales'
};

export const COMPENSATION_INVESTIGATION_REASONS = [
  'Agent mistake',
  'Playbook',
  'Less than 100 LE',
  'Social media',
  'First incident',
  'Insurance',
  'Fulfillment',
  'Tech issue'
];

export const COMPENSATION_DEPARTMENTS_NAMES = {
  INVESTIGATION: 'Experience'
};

export const renderCashoutTooltip = (frequency, daysSchedule) => {
  const { NEXT_DAY, DAILY } = CASHOUT_FREQUENCY;

  const tooltip = [NEXT_DAY, DAILY].includes(frequency)
    ? 'wallet.balance.header.cashout.cashout_frequency_daily_tooltip'
    : daysSchedule.length === 2
    ? 'wallet.balance.header.cashout.cashout_frequency_two_days_week_tooltip'
    : daysSchedule.length === 3
    ? 'wallet.balance.header.cashout.cashout_frequency_three_days_week_tooltip'
    : 'wallet.balance.header.cashout.cashout_frequency_weekly_wednesday_tooltip';

  return fmt({ id: tooltip });
};

export const renderCashoutFrequencyValue = (frequency, daysSchedule) => {
  const { NEXT_DAY, DAILY } = CASHOUT_FREQUENCY;

  const tooltip = [NEXT_DAY, DAILY].includes(frequency)
    ? 'wallet.balance.header.cashout.cashout_frequency_next_day'
    : daysSchedule.length === 2
    ? 'wallet.balance.header.cashout.cashout_frequency_two_days_week'
    : daysSchedule.length === 3
    ? 'wallet.balance.header.cashout.cashout_frequency_three_days_week'
    : 'wallet.balance.header.cashout.cashout_frequency_weekly';

  return fmt({ id: tooltip });
};

export const LIABILITY_VALUES = {
  HUB: 'Hub',
  STAR: 'Star',
  OPS_BUDGET: 'OPS Budget'
};

export const EXCHANGE_COUNTRY_OPTIONS = {
  SA: 'sa',
  AE: 'ae'
};

export const INTERNATIONAL_COUNTRY_CURRENCY_ID = {
  EG: 'common.SAR_amount',
  AE: 'common.aed_amount'
};

export const CUSTOMS_VAT_TYPE = {
  DDU: 'DDU',
  DDP: 'DDP'
};

export const EMPTY_STATE_IMAGES = {
  FINANCIAL_VISIBILITY: 'FINANCIAL_VISIBILITY',
  TRACK_CASH: 'TRACK_CASH',
  CASHOUT_DATE: 'CASHOUT_DATE'
};

export const EMPTY_STATE_SECTIONS = [
  {
    key: EMPTY_STATE_IMAGES.FINANCIAL_VISIBILITY,
    title: fmt({ id: 'wallet.balance.empty_state.financial_visibility_title' }),
    subTitle: fmt({
      id: 'wallet.balance.empty_state.financial_visibility_sub_title'
    })
  },
  {
    key: EMPTY_STATE_IMAGES.TRACK_CASH,
    title: fmt({ id: 'wallet.balance.empty_state.track_cash_title' }),
    subTitle: fmt({ id: 'wallet.balance.empty_state.track_cash_sub_title' })
  },
  {
    key: EMPTY_STATE_IMAGES.CASHOUT_DATE,
    title: fmt({ id: 'wallet.balance.empty_state.cashout_date_title' }),
    subTitle: fmt({ id: 'wallet.balance.empty_state.cashout_date_sub_title' })
  }
];
