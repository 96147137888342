import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Radio, Select, InputNumber, Tooltip, Checkbox } from 'antd';

import {
  COMPENSATION_EXCLUDED_SIZES,
  COMPENSATION_REASONS,
  COMPENSATION_TYPE,
  LARGE_AMOUNT_APPROVAL,
  MIN_TN_LENGTH
} from 'constants/compensation';
import { getPricingSizes } from 'services/pricing';
import { getDepartmentsApprovals } from 'services/compensation';
import { getHubs } from 'services/hubs';

import { notify } from 'components/Notify/Notify';
import DepartmentLiability from 'components/AddCompensationFormModal/components/CompensationInfoSection/DepartmentLiability';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import PriceBreakdownToolTip from 'components/AddCompensationFormModal/components/PriceBreakdownToolTip/PriceBreakdownToolTip';

import { ReactComponent as CalculatorIcon } from 'assets/bosta-icons/calculator.svg';

const LiabilityFormSection = ({
  formRef,
  disableCompensationType,
  departmentsPerReason,
  selectedDeliveries,
  disableNoOfOrders,
  selectedReason,
  totalAmount,
  deparments,
  setDepartments,
  deliveryPricing,
  intl
}) => {
  const [compensationType, setCompensationType] = useState(null);
  const [hubs, setHubs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [approvedByLargeAmountError, setApprovedByLargeAmountError] = useState({
    MD: false,
    CFO: false
  });
  const [sizeOptions, setSizeOptions] = useState([]);

  const { reason } = selectedReason || {};

  const handleTypeChange = (e) => {
    setCompensationType(e.target.value);
  };

  const fetchHubs = async () => {
    try {
      const { result } = await getHubs();
      const mappedHubs = result.map((hub) => ({
        key: hub._id,
        label: hub.name,
        value: hub.name
      }));
      setHubs(mappedHubs);
    } catch (error) {
      notify(error.message);
    }
  };

  useEffect(() => {
    fetchHubs();
  }, []);

  const fetchDepartments = async () => {
    try {
      setLoading(true);
      const payload = {
        compensationType: compensationType,
        calculateOverBudget: true
      };

      const { data } = await getDepartmentsApprovals(payload);
      const filteredDeps = data.filter((dep) =>
        departmentsPerReason.includes(dep.name)
      );

      setDepartments(filteredDeps);
    } catch (error) {
      notify(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    compensationType && fetchDepartments();
  }, [departmentsPerReason, compensationType]);

  const getSizesArray = async () => {
    try {
      const { data } = await getPricingSizes(deliveryPricing?.tier._id);

      const filtered = data?.sizes.filter(
        (item) => !COMPENSATION_EXCLUDED_SIZES.includes(item.name)
      );

      const mappedSizes = filtered?.map(({ name }) => ({
        value: name,
        label: name
      }));

      setSizeOptions(mappedSizes);
    } catch (error) {
      notify(error.message);
    }
  };

  useEffect(() => {
    if (reason === COMPENSATION_REASONS.WRONG_PACKAGE_SIZE) {
      getSizesArray();
    }
  }, [reason]);

  useEffect(() => {
    // Clear the form items dependent on reason
    selectedDeliveries.forEach((order) => {
      formRef.current.setFieldsValue({
        noOfFreeOrder: {
          [order.trackingNumber]: '1'
        }
      });
    });
    setCompensationType(null);
    formRef.current.setFieldsValue({
      compensationType: null,
      department: null
    });
  }, [reason]);

  useEffect(() => {
    const { free, credit } = disableCompensationType;
    if (free && !credit) {
      formRef.current.setFieldsValue({
        compensationType: COMPENSATION_TYPE.CREDIT
      });
      setCompensationType(COMPENSATION_TYPE.CREDIT);
    }
    if (!free && credit) {
      formRef.current.setFieldsValue({
        compensationType: COMPENSATION_TYPE.FREE
      });
      setCompensationType(COMPENSATION_TYPE.FREE);
    }
  }, [reason, disableCompensationType]);

  return (
    <div className="br-compensation__form-departments-section">
      <div className="br-compensation__form-section-header">
        <CalculatorIcon /> Compensation Liability
      </div>
      <Form.Item
        formRef={formRef}
        className="br-compensation__order-type-radio"
        name="compensationType"
        label={intl.formatMessage({
          id: 'request_compensation_modal.form_title.compensation_type_title'
        })}
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'request_compensation_modal.validation_messages.compensation_type'
            })
          }
        ]}
      >
        <Radio.Group onChange={handleTypeChange} value={compensationType}>
          <Radio
            value={COMPENSATION_TYPE.FREE}
            disabled={disableCompensationType.free}
          >
            {intl.formatMessage({
              id: 'request_compensation_modal.form_title.free_orders_title'
            })}
          </Radio>
          <Radio
            value={COMPENSATION_TYPE.CREDIT}
            disabled={disableCompensationType.credit}
          >
            {intl.formatMessage({
              id: 'request_compensation_modal.form_title.credit_title'
            })}
          </Radio>
        </Radio.Group>
      </Form.Item>

      <LoadingWrapper loading={loading}>
        {compensationType === COMPENSATION_TYPE.FREE && (
          <div className="br-compensation-form__no-of-items-container">
            {selectedDeliveries.map(
              (order) =>
                order &&
                order?.trackingNumber?.length > MIN_TN_LENGTH && (
                  <div
                    className="br-compensation-form__no-of-items-content"
                    key={order?.trackingNumber}
                  >
                    <span className="br-compensation-form__order-id-text">
                      #{order?.trackingNumber}
                    </span>
                    <Form.Item
                      className="br-compensation__order-type-radio"
                      name={['noOfFreeOrder', order?.trackingNumber]}
                      rules={[
                        {
                          required: true,
                          message: 'Please select a number'
                        }
                      ]}
                      initialValue="1"
                    >
                      <InputNumber
                        min="1"
                        max="10"
                        disabled={!disableNoOfOrders}
                        className="br-compensation__input-number-field"
                      />
                    </Form.Item>
                    <span className="br-compensation-info__x-text">X</span>
                    <Tooltip
                      title={<PriceBreakdownToolTip deliveryInfo={order} />}
                      overlayClassName="br-compensation-info-tooltip-container"
                    >
                      <span className="br-compensation-info__price-text">
                        {order?.pricing?.currency}{' '}
                        {Math.round(order?.pricing?.priceAfterVat * 100) /
                          100 ?? 0}
                      </span>
                    </Tooltip>
                  </div>
                )
            )}
          </div>
        )}

        {!!sizeOptions.length &&
          reason === COMPENSATION_REASONS.WRONG_PACKAGE_SIZE && (
            <Form.Item
              name="sizeConfirmation"
              label={intl.formatMessage({
                id: 'request_compensation_modal.form_title.size_confirmation'
              })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'request_compensation_modal.validation_messages.size_confirmation'
                  })
                }
              ]}
            >
              <Select options={sizeOptions} placeholder="size confirmation" />
            </Form.Item>
          )}

        {/* departments per reason */}
        {compensationType && (
          <Form.Item
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'request_compensation_modal.validation_messages.dep_confirmation'
                })
              }
            ]}
            name="department"
            label={intl.formatMessage({
              id: 'request_compensation_modal.form_title.assigned_departments'
            })}
          >
            <Checkbox.Group>
              {deparments?.map((department) => (
                <DepartmentLiability
                  formRef={formRef}
                  hubs={hubs}
                  key={department._id}
                  department={department}
                  compensationType={compensationType}
                  reason={selectedReason}
                />
              ))}
            </Checkbox.Group>
          </Form.Item>
        )}

        {/* large amount additional approvals */}
        {totalAmount >= LARGE_AMOUNT_APPROVAL.MD && (
          <div className="br-compensation__extra-checks">
            <Form.Item
              label={intl.formatMessage({
                id: 'request_compensation_modal.form_title.approved_by'
              })}
              name="additionalApproval"
              rules={[
                {
                  validator(rule, value, callback, source, options) {
                    const optionsLength =
                      totalAmount >= LARGE_AMOUNT_APPROVAL.CFO
                        ? 2
                        : totalAmount >= LARGE_AMOUNT_APPROVAL.MD
                        ? 1
                        : 0;
                    if (!value?.length) {
                      setApprovedByLargeAmountError({
                        CFO: true,
                        MD: true
                      });
                      return Promise.reject(
                        intl.formatMessage({
                          id: 'request_compensation_modal.validation_messages.extra_checks_err'
                        })
                      );
                    } else if (value?.length !== optionsLength) {
                      setApprovedByLargeAmountError({
                        MD: true,
                        CFO: true,
                        [value[0]]: false
                      });
                      return Promise.reject(
                        intl.formatMessage({
                          id: 'request_compensation_modal.validation_messages.extra_checks_both_required'
                        })
                      );
                    } else {
                      setApprovedByLargeAmountError({
                        MD: false,
                        CFO: false
                      });
                      return Promise.resolve();
                    }
                  }
                }
              ]}
            >
              <Checkbox.Group>
                <div className="br-compensation__extra-checks-items">
                  <div className="br-compensation__extra-checks-item">
                    <Checkbox value="MD">
                      {intl.formatMessage({
                        id: 'request_compensation_modal.md'
                      })}
                    </Checkbox>
                    {approvedByLargeAmountError.MD && (
                      <p className="br-compensation__extra-checks-err">
                        {intl.formatMessage({
                          id: 'request_compensation_modal.validation_messages.md_error'
                        })}
                      </p>
                    )}
                  </div>
                  <div>
                    {totalAmount >= LARGE_AMOUNT_APPROVAL.CFO && (
                      <>
                        <Checkbox value="CFO">
                          {intl.formatMessage({
                            id: 'request_compensation_modal.cfo'
                          })}
                        </Checkbox>
                        {approvedByLargeAmountError.MD && (
                          <p className="br-compensation__extra-checks-err">
                            {intl.formatMessage({
                              id: 'request_compensation_modal.validation_messages.cfo_error'
                            })}
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Checkbox.Group>
            </Form.Item>
          </div>
        )}
      </LoadingWrapper>
    </div>
  );
};

export default injectIntl(LiabilityFormSection);
