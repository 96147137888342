import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Input, Radio, Select } from 'antd';

import { conatinUrlRule } from 'forms';
import { COMPENSATION_TYPE, ORDER_TYPE } from 'constants/compensation';
import { getComepnsationReasons } from 'services/compensation';
import { checkOrderExistance } from 'services/hubs';

import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';
import BusinessInfoSection from 'components/AddCompensationFormModal/components/BusinessInfoSection/BusinessInfoSection';
import LiabilityFormSection from 'components/AddCompensationFormModal/components/CompensationInfoSection/LiabilityFormSection';

import { ReactComponent as DocumentIcon } from 'assets/bosta-icons/Document.svg';

import './CompensationInfoSection.less';

const CompensationForm = ({
  intl,
  delivery,
  onSubmit,
  form,
  formRef,
  totalAmount,
  amountLoading,
  selectedReasonObj,
  setSelectedReasonObj,
  departmentsPerReason,
  setDepartmentsPerReason,
  handleOnChange,
  selectedDeliveries,
  setSelectedDeliveries,
  deparments,
  setDepartments,
  recalcuate
}) => {
  const [compesationReasons, setCompensationReasons] = useState([]);
  const [isMultipleIds, setIsMultipleIds] = useState(false);
  const [hasMoreThanOne, setHasMoreThanOne] = useState(false);
  const [disableNoOfOrders, setDisableNoOfOrders] = useState(true);
  const [ordersArray, setOrdersArray] = useState([delivery?.trackingNumber]);
  const [orderIdConfirmed, setOrderIdConfirmed] = useState(true);
  const [showInvalidError, setShowInvalidError] = useState(null);
  const [disableOrderIdInput, setDisableOrderIdInput] = useState(true);
  const [disableCompensationType, setDisableCompensationType] = useState({
    free: false,
    credit: false
  });
  const [loading, setLoading] = useState(false);

  const orderType = Form.useWatch('orderType', form) || ORDER_TYPE.SINGLE;
  const orderId = Form.useWatch('orderId', form);

  useEffect(() => {
    setDisableOrderIdInput(orderType === ORDER_TYPE.SINGLE);
    setOrderIdConfirmed(orderType === ORDER_TYPE.SINGLE);

    if (orderType === ORDER_TYPE.SINGLE && orderId?.includes(',')) {
      const initialTrackingNumber = delivery.trackingNumber;
      const initialDelivery = selectedDeliveries.find(
        (delivery) => delivery.trackingNumber === initialTrackingNumber
      );

      setSelectedDeliveries([initialDelivery]);
      form.resetFields(['noOfFreeOrder']);
      form.setFieldsValue({
        orderId: initialTrackingNumber,
        noOfFreeOrder: {
          [initialTrackingNumber]: '1'
        }
      });
    }
  }, [orderType]);

  useEffect(() => {
    const getCompensationInfo = async () => {
      try {
        const { data: compensationReasons } = await getComepnsationReasons();
        setCompensationReasons(compensationReasons);

        const deliveryDetails = await checkOrderExistance({
          ids: delivery.trackingNumber
        });
        setSelectedDeliveries(deliveryDetails);
      } catch (error) {
        notify(error.message);
      }
    };

    getCompensationInfo();
  }, []);

  const validateMultipleOrder = async () => {
    setLoading(true);
    try {
      const ids = ordersArray.join(',');
      const result = await checkOrderExistance({ ids });
      setSelectedDeliveries(result);
      if (ordersArray.length !== result?.length) {
        setShowInvalidError(
          intl.formatMessage({
            id: 'request_compensation_modal.validation_messages.invalid_tracking_numbers'
          })
        );
      } else {
        result.forEach((d) => {
          if (d?.sender?._id !== delivery?.sender._id) {
            setShowInvalidError(
              intl.formatMessage({
                id: 'request_compensation_modal.validation_messages.tracking_numbers_dont_belong_to_business'
              })
            );
            result = [];
            return;
          }
        });

        if (result?.length) {
          setOrderIdConfirmed(true);

          formRef.current.getFieldValue('compensationType') ===
            COMPENSATION_TYPE.FREE &&
            result.forEach((delivery) =>
              formRef.current.setFieldsValue({
                noOfFreeOrder: {
                  [delivery?.trackingNumber]: '1'
                }
              })
            );

          recalcuate();
        }
      }
    } catch (error) {}

    setLoading(false);
  };

  const handleReasonChange = (value) => {
    const reason =
      compesationReasons.find((item) => item.reason === value) || {};
    setSelectedReasonObj(reason);

    const deps = reason?.assignedDepartments?.map((dep) => dep.name);
    setDepartmentsPerReason(deps);

    setIsMultipleIds(reason?.isMultipleOrderCompensation);

    setDisableCompensationType(
      reason?.compensationType?.length === 1
        ? reason?.compensationType[0].type === COMPENSATION_TYPE.FREE
          ? {
              free: false,
              credit: true
            }
          : {
              free: true,
              credit: false
            }
        : {
            free: false,
            credit: false
          }
    );

    setDisableNoOfOrders(
      reason?.compensationType[0].type === COMPENSATION_TYPE.FREE &&
        reason?.compensationType[0].hasMoreThanOne
    );

    const freeType = reason?.compensationType.find(
      (type) => type.type === COMPENSATION_TYPE.FREE
    );
    setHasMoreThanOne(freeType?.hasMoreThanOne);
  };

  const handleIdsChange = (e) => {
    const ids = e.target.value;
    const numberRegex = /^\d+$/;

    const array = ids.split(',');
    const mapped = array.map((id) => {
      if (!numberRegex.test(id.trim())) {
        setShowInvalidError('invalid');
      }
      setShowInvalidError(null);
      return id.trim();
    });

    if (mapped.length > 50) {
      setShowInvalidError(
        intl.formatMessage({
          id: 'request_compensation_modal.validation_messages.more_than_50_id'
        })
      );
      return;
    }
    setOrdersArray(mapped);
  };

  return (
    <div className="br-compensation-info-content-container">
      <Form
        form={form}
        layout="vertical"
        ref={formRef}
        scrollToFirstError={true}
        onFinish={onSubmit}
        onValuesChange={handleOnChange}
        initialValues={{ orderId: delivery?.trackingNumber, orderType }}
        name="compensationInfoForm"
      >
        <div className="br-compensation__form-order-details">
          <div>
            <div className="br-compensation__form-section-header">
              <DocumentIcon /> Compensation Details
            </div>
            <div className="br-compensation__form-order-details-leftside">
              <Form.Item
                label={intl.formatMessage({
                  id: 'request_compensation_modal.form_title.ticket_url'
                })}
                extra={intl.formatMessage({
                  id: 'request_compensation_modal.form_title.ticket_url_subtitle'
                })}
                name="ticketUrl"
                validateFirst
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'request_compensation_modal.validation_messages.ticket_url_required'
                    })
                  },
                  conatinUrlRule(
                    intl.formatMessage({
                      id: 'request_compensation_modal.validation_messages.invalid_url'
                    })
                  )
                ]}
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: 'request_compensation_modal.form_placeholders.ticket_url'
                  })}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({
                  id: 'request_compensation_modal.form_title.compensation_reason'
                })}
                name="compensationReason"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'request_compensation_modal.validation_messages.compensation_reason'
                    })
                  }
                ]}
              >
                <Select
                  placeholder={intl.formatMessage({
                    id: 'request_compensation_modal.form_placeholders.compensation_reason'
                  })}
                  onChange={handleReasonChange}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {compesationReasons?.map((reason) => (
                    <Select.Option key={reason?._id} value={reason.reason}>
                      {reason?.reason}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              {isMultipleIds && (
                <Form.Item
                  className="br-compensation__order-type-radio"
                  name="orderType"
                >
                  <Radio.Group defaultValue="single">
                    <Radio value="single">
                      {intl.formatMessage({
                        id: 'request_compensation_modal.form_title.single_order'
                      })}
                    </Radio>
                    <Radio value="multiple">
                      {intl.formatMessage({
                        id: 'request_compensation_modal.form_title.multiple_orders'
                      })}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              )}

              <Form.Item
                label={intl.formatMessage({
                  id: `request_compensation_modal.form_title.${
                    disableOrderIdInput ? 'order_id' : 'multiple_order_title'
                  }`
                })}
                extra={
                  isMultipleIds
                    ? intl.formatMessage({
                        id: 'request_compensation_modal.form_title.multiple_orders_subtitle'
                      })
                    : ''
                }
                name="orderId"
                validateFirst
                validateStatus={showInvalidError ? 'error' : null}
                help={showInvalidError}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'request_compensation_modal.validation_messages.order_ids'
                    })
                  }
                ]}
              >
                <Input
                  onChange={handleIdsChange}
                  placeholder={
                    disableOrderIdInput
                      ? null
                      : intl.formatMessage({
                          id: `request_compensation_modal.form_placeholders.multiple_order_id`
                        })
                  }
                  disabled={disableOrderIdInput || orderIdConfirmed}
                  suffix={
                    !disableOrderIdInput && (
                      <BRButton
                        type="link-color"
                        label={intl.formatMessage({
                          id: 'common.confirm'
                        })}
                        className="br-compensation-info__confrim-order-id-button"
                        disabled={showInvalidError || orderIdConfirmed}
                        loading={loading}
                        onClick={
                          !orderIdConfirmed ? validateMultipleOrder : () => {}
                        }
                      />
                    )
                  }
                />
              </Form.Item>
            </div>
          </div>

          <BusinessInfoSection
            amountLoading={amountLoading}
            delivery={delivery}
            totalAmount={totalAmount}
          />
        </div>
        {selectedReasonObj && orderIdConfirmed && (
          <LiabilityFormSection
            departmentsPerReason={departmentsPerReason}
            disableCompensationType={disableCompensationType}
            formRef={formRef}
            selectedDeliveries={selectedDeliveries}
            disableNoOfOrders={disableNoOfOrders}
            hasMoreThanOne={hasMoreThanOne}
            selectedReason={selectedReasonObj}
            deparments={deparments}
            setDepartments={setDepartments}
            totalAmount={totalAmount}
            deliveryPricing={selectedDeliveries[0]?.pricing}
          />
        )}
      </Form>
    </div>
  );
};

export default injectIntl(CompensationForm);
